import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { Wrapper } from "./components/pages/wrapper";
import { Dashboard } from "./components/pages/dashboard";

import { disease_burden } from './data/data'
import { ChildHealth } from "./components/pages/child_health";
import { MaternalHealth } from "./components/pages/maternal_health";

// Create a client
const queryClient = new QueryClient();

export default function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Wrapper>
					<Switch>
						<Route path="/child-health" component={ChildHealth} />
						<Route path="/maternal-health" component={MaternalHealth} />
						{ disease_burden.map(
							disease => <Route path={`/disease-burden/${disease.route.toLowerCase()}`} component={disease.component} />
						)}
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/" component={Dashboard} />
						<Route component={Dashboard} />
					</Switch>
				</Wrapper>
			</Router>
		</QueryClientProvider>
	);
}
