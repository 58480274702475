import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

export const AreaChartComponent = ({data, xkey, ykey, fill="#eb4334"}) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<AreaChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
					</linearGradient>
				</defs>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xkey} />
				<YAxis dataKey={ykey} />
				<Tooltip />
				{/* <Legend /> */}
				<Area
					type="monotone"
					dataKey={ykey}
					stroke={fill}
					activeDot={{ r: 8 }}
					fill="url(#colorUv)"
					fillOpacity={1}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};
