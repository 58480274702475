import { ChildDeliveries, DiseaseBurden } from "../../sections";
import { BarChartComponent, AreaChartComponent } from "../../visuals";

import React from "react";

import { Panel, Row, Col, Container, Content, Header, Loader, InputPicker, Carousel, Toggle, Icon } from "rsuite";

import { population } from "../../../lib";

import { useFetchData } from "../../../hooks/useFetchHooks";

import { notification } from "../../notification";

import { PanelHeader } from "../../panelHeader";
import { karamoja_districts, access_mode, covid_cases_may, all_time_covid_cases, covid_cases_july } from "../../../data/data";

import firebase from "../../../config/firebaseConfig";

import { _loadCategories, _loadBurden, _loadReview } from "../../../data/cases";

export const Dashboard = () => {
	let [accessMode, setAccessMode] = React.useState("National Overview");
	let [carouselAutoplay, changeCarouselAutoplay] = React.useState(true);
	let [cases, setCases] = React.useState([]);
	let [burden, setBurden] = React.useState([]);
	let [review, setReview] = React.useState([]);

	let exportData = React.useRef();

	let popn_data = useFetchData("population");
	let { year_popn, district_popn } = population(popn_data.data);

	React.useEffect(() => {
		document.title = "Dashboard — GAMERS";
		let cases = [],
			burden = [],
			conditions = [];
		firebase
			.database()
			.ref("emergency_cases")
			.on("value", (snapshot) => {
				let c = [];
				snapshot.forEach((child) => {
					c.push(child.val());
				});
				cases = c;
				setCases(_loadCategories(cases));
				notification("info", "Emergencies", "Emergency case categories loaded");
			});
		firebase
			.database()
			.ref("emergency_cases_data")
			.on("value", (snapshot) => {
				let c = [];
				snapshot.forEach((child) => {
					c.push(child.val());
				});
				burden = c;
				setBurden(_loadBurden(burden));
				// notification("info", "Emergencies", "Disease Burden Updated");
			});
		firebase
			.database()
			.ref("emergency_cases_data")
			.on("value", (snapshot) => {
				let c = [];
				snapshot.forEach((child) => {
					c.push(child.val());
				});
				conditions = c;
				setReview(_loadReview(conditions));
				// notification("info", "Emergencies", "Review Updated");
			});
	}, []);

	const populationData = () => {
		switch (popn_data.status) {
			case "loading":
				return <Loader content="Loading..." />;
			case "success":
				notification("success", "Population Data Loaded", "Population per district ready to be visualised");
				return <AreaChartComponent data={district_popn} xkey="district" ykey="population" fill="#eb4334" />;
			case "error":
				notification("error");
				break;
		}
	};

	const populationPerYearData = () => {
		switch (popn_data.status) {
			case "loading":
				return <Loader content="Loading..." />;
			case "success":
				// notification("success", "Population Data Loaded", "Data is ready to be visualised");
				return <BarChartComponent data={year_popn} xkey="year" ykey="population" fill="#8884d8" />;
			case "error":
				notification("error");
				break;
		}
	};

	return (
		<Container style={{ padding: 10, paddingLeft: 65 }}>
			<Header style={{ margin: 10 }}>
				<span>GAMERS DATA HUB (beta) - {accessMode}</span>
				<h2>Dashboard</h2>
				<Toggle checked={carouselAutoplay} checkedChildren={<Icon icon="pause" />} unCheckedChildren={<Icon icon="play" />} onChange={(value) => changeCarouselAutoplay(value)} />
				<InputPicker data={access_mode} style={{ width: 224, margin: 5 }} placeholder="Access Level" value={accessMode} onChange={(value) => setAccessMode(value)} />
			</Header>
			<Content>
				<Row>
					<Col md={23} sm={23} lg={23} style={{ margin: 5, marginRight: 0, marginLeft: 10 }}>
						<Carousel autoplay={carouselAutoplay} className="custom-slider" placement="left" autoplayInterval={5000}>
							<Panel header={<PanelHeader header="COVID-19 Cases for 2021" type="covid" route="/disease-burden/pandemic/covid-19" r={exportData} />} bordered ref={exportData} style={{ color: "#fff" }}>
								<div style={{ height: 250, width: "100%" }}>
									<BarChartComponent data={all_time_covid_cases} xkey="Date" ykey="New Cases" fill="#a6aa2c" />
									{notification("success", "COVID-19 Updates", "COVID-19 Data for the month of May loaded")}
								</div>
							</Panel>
							<Panel header={<PanelHeader header="Population Per District in Karamoja Region" type="dashboard" r={exportData.current} />} bordered ref={exportData}>
								<div style={{ height: 250, width: "100%" }}>{populationData()}</div>
							</Panel>
							<Panel header={<PanelHeader header="Population Per Year in Karamoja Region" type="dashboard" r={exportData} />} bordered ref={exportData}>
								<div style={{ height: 250, width: "100%" }}>{populationPerYearData()}</div>
							</Panel>
						</Carousel>
					</Col>
					<Col md={9} sm={23} style={{ margin: 5 }}>
						<Panel header={<PanelHeader header="Emergencies" type="dashboard" />} bordered>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={cases} xkey="category" ykey="Number of Cases" fill="#6e65a0" />
							</div>
						</Panel>
					</Col>
					<Col md={7} sm={23} style={{ margin: 5, marginLeft: 0 }}>
						<Panel header={<PanelHeader header="Disease burden in Karamoja" type="dashboard" />} bordered>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={burden} xkey="category" ykey="Number of Cases" fill="#6e65a0" />
							</div>
						</Panel>
					</Col>
                    <Col md={7} sm={23} style={{ margin: 5, marginLeft: 0 }}>
						<Panel header={<PanelHeader header="GAMERS outcomes" type="dashboard" />} bordered>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={review} xkey="status" ykey="Number of patients" fill="#eb4334" />
							</div>
						</Panel>
					</Col>
					<Col md={16} sm={23} style={{ margin: 5 }}>
						<Panel header={<PanelHeader header="May COVID-19 Cases" type="covid" route="/disease-burden/pandemic/covid-19" />} bordered style={{ color: "#fff" }}>
							<div style={{ height: 200, width: "100%" }}>
								<AreaChartComponent data={covid_cases_may} xkey="Date" ykey="New Cases" fill="yellow" />
							</div>
						</Panel>
					</Col>
					<Col md={7} sm={23} style={{ margin: 5 }}>
						<Panel header={<PanelHeader header="July COVID-19 Cases" type="covid" route="/disease-burden/pandemic/covid-19" />} bordered style={{ color: "#fff" }}>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={covid_cases_july} xkey="Date" ykey="New Cases" fill="#eb4334" />
							</div>
						</Panel>
					</Col>
				</Row>
			</Content>
		</Container>
	);
};
