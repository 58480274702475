import { MalariaBurden, DiarrheaBurden, PneumoniaBurden, CovidBurden } from "../components/pages/disease_burden"

export const disease_burden = [{
    title: "Diarrhea",
    route: "diarrhea",
    component: DiarrheaBurden,
    index: 1
}, {
    title: "Malaria",
    route: "malaria",
    component: MalariaBurden,
    index: 2
}, {
    title: "Pneumonia",
    route: "pneumonia",
    component: PneumoniaBurden,
    index: 3,
}, {
    title: "COVID-19",
    route: "pandemic/covid-19",
    component: CovidBurden,
    index: 4
}]

export const village_health_teams = [{
    title: "Stock",
    route: "stock",
    component: "StockInformation",
    index: 1,
}]

export const regions = [
    {
      "label": "Karamoja",
      "value": "Karamoja",
    },
    {
      "label": "Western",
      "value": "Western",
    },
    {
      "label": "Central",
      "value": "Central",
    },
]

export const karamoja_districts = [
    {
        "label": "Kotido",
        "value": "Kotido",
    },
    {
        "label": "Moroto",
        "value": "Moroto",
    },
    {
        "label": "Nakapiripirit",
        "value": "Nakapiripirit",
    },
    {
        "label": "Navilatuk",
        "value": "Navilatuk",
    },
    {
        "label": "Abim",
        "value": "Abim",
    },
    {
        "label": "Kaabong",
        "value": "Kaabong",
    },
    {
        "label": "Amudat",
        "value": "Amudat",
    },
    {
        "label": "Napak",
        "value": "Napak",
    },
]

export const covid_cases_june = []
export const covid_cases_may = []
export const covid_cases_april = []
export const covid_cases_march = []
export const covid_cases_february = []
export const covid_cases_january = []
export const covid_cases_july = []
export const all_time_covid_cases = []

const new_covid_cases_june = [null, 614, 915, 1083, 1247, 670, 1259, 1026, 708, 842, 1438, 1566, 1735, 1727, 1122, 1422, 1110, 1584, 1564, 1397, 1367, 1136, 722, 859, 1277, 1025, 943, 889, 695, 345]
const new_covid_cases_july = [543, 2106, 770, 784, null, 479, 438, 425, 602, 559, 615, 522, 479, 438, 480, 406, 434, 460, 682]
const new_covid_cases_january = [539, null, 496, 210, 128, null, 652, 372, 222, 258, 188, 66, 140, 137, null, null, null, null, null, 449, 94, 178, 129, 109, 105, 39, 73, 53, 110, 66, 43]
const new_covid_cases_february = [46, 27, 45, 34, 50, 54, 32, 27, 12, 23, 28, 31, 37, 40, null, 36, 26, 21, 52, 14, 31, 14, 8, 22, 18, 39, 22, 13]
const new_covid_cases_march = [22, 10, 28, 13, 18, 26, null, 12, 21, 5, 30, 15, 9, null, 37, 10, 2, 14, 18, 26, null, 20, 16, 32, null, 14, 17, 17, 53, null, 19]
const new_covid_cases_april = [28, 22, 35, 38, null, 54, 7, 23, 16, 24, 27, 27,34, 30, 33, 26, 47, 30, 38, 18, 26, 53, 54, 46, null, 80, 60, 22, 29, 31]
const new_covid_cases_may = [110, null, 66, null, 59, 70, 50, 72, 84, 76, null, 43, 60, 91, 96, null, 105, 80, 71, 164, 129, 147, 137, 227, 185, 155, 207, 313, 637, 700, 1216]

for (let i = 1, j = 0; i <= new_covid_cases_january.length; ++i, ++j) {
    covid_cases_january.push({"New Cases": new_covid_cases_january[j], "Date": `${i} January`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_january[j], "Date": `${i} January`})
}

for (let i = 1, j = 0; i <= new_covid_cases_february.length; ++i, ++j) {
    covid_cases_february.push({"New Cases": new_covid_cases_february[j], "Date": `${i} February`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_february[j], "Date": `${i} February`})
}

for (let i = 1, j = 0; i <= new_covid_cases_march.length; ++i, ++j) {
    covid_cases_march.push({"New Cases": new_covid_cases_march[j], "Date": `${i} March`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_march[j], "Date": `${i} March`})
}

for (let i = 1, j = 0; i <= new_covid_cases_april.length; ++i, ++j) {
    covid_cases_april.push({"New Cases": new_covid_cases_april[j], "Date": `${i} April`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_april[j], "Date": `${i} April`})
}

for (let i = 1, j = 0; i <= new_covid_cases_may.length; ++i, ++j) {
    covid_cases_may.push({"New Cases": new_covid_cases_may[j], "Date": `${i} May`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_may[j], "Date": `${i} May`})
}

for ( let i = 1, j = 0; i <= new_covid_cases_june.length; ++i, ++j) {
    covid_cases_june.push({"New Cases": new_covid_cases_june[j], "Date": `${i} June`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_june[j], "Date": `${i} June`})
}

for ( let i = 1, j = 0; i <= new_covid_cases_july.length; ++i, ++j) {
    covid_cases_july.push({"New Cases": new_covid_cases_july[j], "Date": `${i} July`})
    all_time_covid_cases.push({"New Cases": new_covid_cases_july[j], "Date": `${i} July`})
}

export const months = [
    {
        "label": "All time",
        "value": "All time"
    },
    {
        "label": "January",
        "value": "January",
    },
    {
        "label": "February",
        "value": "February",
    },
    {
        "label": "March",
        "value": "March",
    },
    {
        "label": "April",
        "value": "April",
    },
    {
        "label": "May",
        "value": "May",
    },
    {
        "label": "June",
        "value": "June",
    },
    {
        "label": "July",
        "value": "July",
    },
    {
        "label": "August",
        "value": "August",
    },
    {
        "label": "September",
        "value": "September",
    },
    {
        "label": "October",
        "value": "October",
    },
    {
        "label": "November",
        "value": "November",
    },
    {
        "label": "December",
        "value": "December",
    }, 
]

export const access_mode = [
    {
        "label": "National Overview",
        "value": "National Overview",
    },
    {
        "label": "District Level",
        "value": "District Level",
    },
    {
        "label": "Parish Level",
        "value": "Parish Level",
    },
    {
        "label": "Village Level",
        "value": "Village Level",
    }
]

export const charts = [
    {
        "label": "Area Graph",
        "value": "Area Graph",
    },
    {
        "label": "Bar Graph",
        "value": "Bar Graph",
    },
]