import firebase from "firebase";

const firebaseConfig = {
	apiKey: "AIzaSyD8fT1DZAnpDlote6cagoG56ZRpJoO6GhI",
	authDomain: "doctorsarch.firebaseapp.com",
	databaseURL: "https://doctorsarch.firebaseio.com",
	projectId: "doctorsarch",
	storageBucket: "doctorsarch.appspot.com",
	messagingSenderId: "858341027161",
	appId: "1:858341027161:web:2982dd5e96a77765bab048",
	measurementId: "G-0E4BQ0YFQQ",
};

firebase.initializeApp(firebaseConfig);

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

