import { useFetchData } from "../../hooks/useFetchHooks"
import { birthdata } from "../../lib"
import { notification } from "../notification"
import { BarChartComponent } from "../visuals"
import { Loader } from "rsuite"

const ChildDeliveries = () => {
    let child_deliveries = useFetchData('birthdata')
    let {birth_location, mother_state} = birthdata(child_deliveries.data)
    
    switch (child_deliveries.status) {
        case 'loading':
            return <Loader content="Loading..." />
        case 'success':
            
            notification('success', 'Outcome of pregnancies among mothers in Karamoja Region', 'Data Updated')
            return (
                <BarChartComponent data={mother_state} xkey="state" ykey="mothers" fill="#34eb8f" />
            )
        case 'error':
            notification('error')
            break;
    }
}

export default ChildDeliveries