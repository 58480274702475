import { Notification } from 'rsuite'

export const notification = (type, title, message, duration = 4500) => {
    switch(type) {
        case 'info':
            Notification.info({
                title,
                description: <span width={100} rows={2}>{message}</span>, 
                duration,
                placement: "bottomEnd"
            });
            break;
            case 'success':
                Notification.success({
                    title,
                    description: <span width={100} rows={2}>{message}</span>,
                    duration
                });
                break
        case 'error':
            Notification.error({
                title: "Network Error",
                description: <span width={100} rows={3}>Please Check your internet connection <a href="https://gamers-stats.doctorsarch.org">Reload</a></span>, 
                duration
            });
            break;
    }
}