export const _loadCategories = (cases) => {
	let child = 0,
		adult_female = 0,
		adult_male = 0,
		pregnant = 0,
		unknown = 0, categories = [];
	cases.forEach((c) => (c?.category === "Child" ? child++ : c?.category === "Adult Female" ? adult_female++ : c?.category === "Adult Male" ? adult_male++ : c?.category === "Pregnant Mother" ? pregnant++ : unknown++));
	categories.push({ category: "Children", "Number of Cases": child }, { category: "Adult Males", "Number of Cases": adult_male }, { category: "Adult Females", "Number of Cases": adult_female }, { category: "Pregnancies", "Number of Cases": pregnant }, { category: "Unknown", "Number of Cases": unknown });
	return categories;
};

export const _loadBurden = (burden) => {
	let malaria = 0,
		pneumonia = 0,
		diarrhea = 0,
		other = 0,
		bur = [];
	burden.forEach((b) => (b?.diagnosis === "Malaria" ? malaria++ : b?.diagnosis === "Pneumonia" ? pneumonia++ : b?.diagnosis === "Diarrhea" ? diarrhea++ : other++));
	bur.push({ category: "Malaria", "Number of cases": malaria }, { category: "Pneumonia", "Number of Cases": pneumonia }, { category: "Diarrhea", "Number of Cases": diarrhea }, { category: "Other Disease", "Number of Cases": other });
	return bur;
};

export const _loadReview = (conditions) => {
	let sick = 0,
		dead = 0,
		better = 0,
		unknown = 0,
		cond = [];
	conditions.forEach((con) => (con?.condition === "sick" ? sick++ : con?.condition === "dead" ? dead++ : con?.condition === "better" ? better++ : unknown++));
	cond.push({ status: "Sick/Worse", "Number of patients": sick }, { status: "Dead", "Number of patients": dead }, { status: "Better/Recovered", "Number of patients": better });
	return cond;
};
