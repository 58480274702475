import { useQuery } from 'react-query';
import axios from 'axios';

let Base_url = 'https://gamers.doctorsarch.org/api/v1';
let Auth_key = 'a211089fbffc6d2179a17639137476575f614746';

let fetchDataByParam = async (queryString) => {
  let { data } = await axios.get(
    `${Base_url}/${queryString}/?token=${Auth_key}`
  );
  return data;
};

export function useFetchData(queryString) {
  
  return useQuery(['receive-data', queryString], () =>
    fetchDataByParam(queryString)
  );
}

