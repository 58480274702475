
import { BarChartComponent, AreaChartComponent } from "../../visuals";

import React from "react";

import { Panel, Row, Col, Container, Content, Header, Loader, InputPicker, Table } from 'rsuite'

import { birthdata } from "../../../lib";

import { useFetchData } from "../../../hooks/useFetchHooks";

import { notification } from "../../notification";

import { covid_cases_june, access_mode } from "../../../data/data";

export const MaternalHealth = () => {

    let [ accessMode, setAccessMode] = React.useState("National Overview")

    let maternal_data = useFetchData('birthdata')
    let { mother_state, baby_state, birth_location } = birthdata(maternal_data.data)

    const birthLocationData = () => {
        switch (maternal_data.status) {
            case 'loading':
                return <Loader content="Loading..." />;
            case 'success':
                notification('success', 'Birth Location', 'Births Location: Home deliveries and Facility deliveries loaded')
                return <BarChartComponent data={birth_location} xkey="location" ykey="births" fill="#f5bc42" />
            case 'error':
                notification('error')
                break;
        }
    }

    const motherStateData = () => {
        switch (maternal_data.status) {
            case 'loading':
                return <Loader content="Loading..." />;
            case 'success':
                notification('success', 'Mother State', 'Statistics of state of mother after birth loaded')
                return <BarChartComponent data={mother_state} xkey="state" ykey="mothers" fill="#3f7d2a" />
            case 'error':
                notification('error')
                break;
        }
    }

    const babyStateData = () => {
        switch (maternal_data.status) {
            case 'loading':
                return <Loader content="Loading..." />;
            case 'success':
                notification('success', 'Babies State', 'Statistics of state of babies after birth loaded')
                return <BarChartComponent data={baby_state} xkey="state" ykey="babies" fill="#7d2a35" />
            case 'error':
                notification('error')
                break;
        }
    }

    return (
        <Container style={{ padding: 10, paddingLeft: 65}}>
            <Header style={{ margin: 10}}>
                <span>GAMERS DATA HUB - { accessMode }</span>
                <h2>Maternal Health</h2>
                <InputPicker data={access_mode} style={{ width: 224, margin: 5}} placeholder="Access Level" value={accessMode} onChange={(value) => setAccessMode(value) }/>
            </Header>
            <Content>
                <Row>
                    <Col md={23} sm={23} lg={23} style={{ margin: 5, marginLeft: 5}}>
                        <Panel header="Ongoing Maternal Births" bordered>
                            <div style={{ height: 470, width: "100%"}}>
                                {maternal_data.isSuccess && <MaternalTable data={maternal_data.data.maternal_birth_data} loading={maternal_data.isLoading}/>}
                            </div>
                        </Panel>
                    </Col>   
                    <Col md={7} sm={23} style={{margin: 5}}>
                        <Panel header="Births Location: Home Deliveries and Facility Births" bordered style={{ color: "#fff"}}>
                            <div style={{ height: 200, width: "100%"}}>
                                {birthLocationData()}
                            </div>
                        </Panel>
                    </Col>
                    <Col md={8} sm={23} style={{ margin: 5}}>
                        <Panel header="State of babies after birth" bordered>
                            <div style={{ height: 200, width: "100%"}}>
                                {babyStateData()}
                            </div>
                        </Panel>
                    </Col>
                    <Col md={8} sm={23} style={{ margin: 5}}>
                        <Panel header="State of mother after birth" bordered>
                            <div style={{ height: 200, width: "100%"}}>
                                {motherStateData()}
                            </div>
                        </Panel>
                    </Col>   
                    <Col md={23} sm={23} md={23} style={{margin: 5, marginLeft: 5}}>
                        <Panel header="COVID-19 Cases for the month of June" bordered>
                            <div style={{ height: 150, width: "100%"}}>
                                <AreaChartComponent data={covid_cases_june} xkey="Date" ykey="New Cases" fill="#eb4334" />
                            </div>
                        </Panel>
                    </Col>
                </Row>
            </Content>
        </Container>
    )
}

const MaternalTable = ({data, loading}) => {
    const { Column, HeaderCell, Cell, Pagination } = Table;

    let [ page, changePage ] = React.useState(1)
    let [ displayLength, changeLength ] = React.useState(10)

    const getData = () => {
        return data.filter((v, i) => {
          const start = displayLength * (page - 1);
          const end = start + displayLength;
          return i >= start && i < end;
        });
    }

    data = getData()

    return (
        <div>
            <Table height={420} data={data.reverse()} loading={loading}>
                <Column width={200} resizable>
                    <HeaderCell>Serial Number</HeaderCell>
                    <Cell dataKey="serialNumber" />
                </Column>
                <Column width={100} align="center" resizable>
                    <HeaderCell>Mother ID</HeaderCell>
                    <Cell dataKey="mothersID" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>Delivery Location</HeaderCell>
                    <Cell dataKey="deliveryLocation" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>Delivery Date</HeaderCell>
                    <Cell dataKey="deliveryDate" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>State of Mother</HeaderCell>
                    <Cell dataKey="motherState" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>State of Baby</HeaderCell>
                    <Cell dataKey="babyState" />
                </Column>
            </Table>
            <Pagination
                lengthMenu={[
                    {
                    value: 10,
                    label: 10
                    },
                    {
                    value: 20,
                    label: 20
                    }
                ]}
                activePage={page}
                displayLength={displayLength}
                total={data.length}
                onChangePage={(dataKey) => changePage(dataKey)}
                onChangeLength={(dataKey) => {
                    changeLength(dataKey)
                    changePage(1)
                }}
            />
      </div>
    )
}