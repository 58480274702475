import { Dropdown, Popover, InputPicker, Whisper, Button, Badge, Alert } from "rsuite";
import { karamoja_districts, months } from "../data/data";

import React from "react";
import { Link } from 'react-router-dom'

import { exportComponentAsJPEG, exportComponentAsPNG, exportComponentAsPDF } from "react-component-export-image";

export const MenuPopover = ({ onSelect, r, ...rest }) => {

    let fileTypes = [{type: "pdf", label: "PDF Document"}, {type: "jpeg", label: "JPEG Document"}, {type: "jpeg", label: "JPG Document"}, {type: "png", label: "PNG Image"}, {type: "html", label: "Export HTML"}]

    const cyrb53 = (str, seed = 0) => {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
    };

    const exportComponent = (val) => {
        let date = Date.now()
        let fileName = {fileName: `gamers_export ${cyrb53(date.toString() )}`}
        Alert.info("Downloading file " + fileName.fileName)
        switch(val) {
            case "pdf":
                exportComponentAsPDF(r, fileName)
                break
            case "jpeg":
                exportComponentAsJPEG(r, fileName)
                break
            case "png":
                exportComponentAsPNG(r, fileName)
                break
            default:
                exportComponentAsJPEG(r, fileName)
                break
        }
        Alert.success(fileName.fileName + " downloaded!")
    }

    return (
        <Popover {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                { fileTypes.map((fileType, index) => <Dropdown.Item eventKey={index+1} onSelect={() => exportComponent(fileType.type)}>{fileType.label}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Popover>
    )
}

export const PanelHeader = ({ header, type, route='/', r=null }) => {

    const triggerRef = React.createRef();

    function handleSelectMenu(eventKey, event) {
        triggerRef.current.hide();
    }

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start"}}>

            <h4>{header}</h4>

            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", marginTop: 10}}>
                
                
                { 
                    type === "dashboard" ? (
                        <span style={{ margin: 5 }}>
                            <InputPicker data={karamoja_districts} style={{ width: 224}} placeholder="Select District"/>
                        </span>
                    ) : type === "covid" ? ( null
                        // <span style={{ margin: 5 }}>
                        //     <InputPicker data={months} style={{ width: 224}} placeholder="Select Month" onChange={value => setMonth(value)}/>
                        // </span>
                    ) : (
                        null
                    )
                }

                { type === "dashboard" && 
                    (
                        <>
                            <span style={{ margin: 5 }}>
                                <Whisper placement="bottomStart" trigger="click" triggerRef={triggerRef} speaker={<MenuPopover onSelect={handleSelectMenu} r={r} />}>
                                    <Button>Export</Button>
                                </Whisper>
                            </span>
                        
                            {/* <Link to={route}>
                                <Badge style={{ margin: 5 }}>
                                    <Button appearance="default">See More</Button>
                                </Badge>
                            </Link> */}
                        </>
                    ) || type === "covid" && (
                        <>
                            <span style={{ margin: 5 }}>
                                <Whisper placement="bottomStart" trigger="click" triggerRef={triggerRef} speaker={<MenuPopover onSelect={handleSelectMenu} r={r}/>}>
                                    <Button>Export</Button>
                                </Whisper>
                            </span>
                        
                            {/* <Link to={route}>
                                <Badge style={{ margin: 5 }}>
                                    <Button appearance="default">See More</Button>
                                </Badge>
                            </Link> */}
                        </>
                    )
                }  

            </div>
        </div>
    )
}
