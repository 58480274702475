import { Container, Sidebar, Navbar, Nav, Dropdown, Icon, Sidenav  } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";

import React from 'react'

import { Link, Redirect, useHistory } from "react-router-dom";
import { disease_burden } from "../../data/data";


const headerStyles = {
    padding: 18,
    fontSize: 16,
    height: 56,
    background: '#34c3ff',
    color: ' #fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  };
  
  const iconStyles = {
    width: 56,
    height: 56,
    lineHeight: '56px',
    textAlign: 'center'
  };
  
  const NavToggle = ({ expand, onChange }) => {
    return (
      <Navbar appearance="subtle" className="nav-toggle">
        <Navbar.Body>
          <Nav pullRight>
            <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
              <Icon icon={expand ? 'angle-left' : 'angle-right'} />
            </Nav.Item>
          </Nav>

          <Nav>
            <Dropdown
              placement="rightStart"
              trigger="hover"
              renderTitle={children => {
                return <Icon style={iconStyles} icon="cog" />;
              }}
            >
              <Dropdown.Item>Help</Dropdown.Item>
              <Dropdown.Item>Settings</Dropdown.Item>
              <Dropdown.Item>Sign out</Dropdown.Item>
            </Dropdown>
          </Nav>
        </Navbar.Body>
      </Navbar>
    );
  };
  
  export const Wrapper = (props) => {

    let [ expand, handleToggle] = React.useState(false)
    let [ mode, changeMode] = React.useState("Dark")

    const changeStyle = () => {

      if (mode === "Dark") {
        import("rsuite/dist/styles/rsuite-dark.css")
        changeMode("Light")
      } else if (mode === "Light") {
        window.location.assign(window.location.origin)
        { <Redirect to="/" /> }
        changeMode("Dark")
      } 
    }

    return (
      <div className="show-fake-browser sidebar-page">

        <Container>

          <Sidebar
            style={{ display: 'flex', flexDirection: 'column', position: "fixed", zIndex: 1000, top: '5%', bottom: 10, left: 5, overflowX: "visible"}}
            width={expand ? 260 : 56}
            collapsible
            shaded
          >
            <NavToggle expand={expand} onChange={() => handleToggle(!expand)} />
            <Sidenav expanded={expand} defaultOpenKeys={['4']} >

              <Sidenav.Header>
                <div style={headerStyles}>
                <img src="../../gamers_icon.png" width={20} height={20} style={{ borderRadius: 5}} />
                  { expand && <span style={{ marginLeft: 12 }}>DATA HUB</span> }
                </div>
              </Sidenav.Header>

              <Sidenav.Body>
                <Nav>
                  <Link to="/dashboard">
                    <Nav.Item eventKey="1" active icon={<Icon icon="dashboard" />}>
                            Dashboard
                    </Nav.Item>
                  </Link>
                  <Link to="/child-health">
                    <Nav.Item eventKey="2" icon={<Icon icon="child" />}>
                          Child Health Data
                    </Nav.Item>
                  </Link>
                  <Link to="/maternal-health">
                    <Nav.Item eventKey="3" icon={<Icon icon="female" />}>
                          Maternal Health Data
                    </Nav.Item>
                  </Link>
                  <Dropdown
                    eventKey="4"
                    trigger="hover"
                    title="Disease Burden"
                    icon={<Icon icon="ambulance" />}
                    placement="rightStart"
                  > 
                    
                    { disease_burden.map((disease, index) => <Link to={`/disease-burden/${disease.route}`}><Dropdown.Item eventKey={`4-${index + 1}`}>{disease.title}</Dropdown.Item></Link> ) }
                  </Dropdown>
                  <Dropdown
                    eventKey="5"
                    trigger="hover"
                    title="Village Health Teams"
                    icon={<Icon icon="peoples" />}
                    placement="rightStart"
                  >
                    <Dropdown.Item eventKey="5-1">Birth Related Data</Dropdown.Item>
                    <Dropdown.Item eventKey="5-2">Immunization</Dropdown.Item>
                    <Dropdown.Item eventKey="5-3">Infant Mortality Rate</Dropdown.Item>
                    <Dropdown.Item eventKey="5-4">ANC & PNC Tracking</Dropdown.Item>
                    <Dropdown.Item eventKey="5-5">VHT Stock </Dropdown.Item>
                  </Dropdown>
                  <Nav.Item eventKey="5" icon={<Icon icon={mode === "Dark" && "moon-o" || "sun-o"} />} onClick={() => changeStyle()}>
                        {mode} Mode
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Sidebar>

          <Container>
              { props.children }
          </Container>
          
        </Container>
      </div>
    )
  }
  