import { useFetchData } from "../../hooks/useFetchHooks"
import { sickness } from "../../lib"
import { notification } from "../notification"
import { BarChartComponent } from "../visuals"
import { Loader } from "rsuite"

const DiseaseBurden = () => {
    let disease_burden = useFetchData('mortalities')
    let data = sickness(disease_burden.data)
    
    switch (disease_burden.status) {
        case 'loading':
            return <Loader content="Loading..." />
        case 'success':
            notification('success', 'Disease Burden', 'Disease Burden in Karamoja for Measles, Pneumonia, Diarrhea updated')
            return (
                <BarChartComponent data={data} xkey="disease" ykey="deaths" fill="#eb4334" />
            )
        case 'error':
            notification('error')
            break;
    }   
}

export default DiseaseBurden