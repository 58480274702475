import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";

export const BarChartComponent = ({data, xkey, ykey, fill="#eb4334"}) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				width={500}
				height={300}
				data={data}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xkey} />
				<YAxis dataKey={ykey} />
				<Tooltip />
				<Legend />
				<Bar dataKey={ykey} fill={fill} />
			</BarChart>
		</ResponsiveContainer>
	);
};
