export const birthdata = (data = { maternal_birth_data: [] }) => {
	let birth_location = [
		// { location: "home", count: ### }
	];

	let mother_state = [
		// { state: "dead", count: ###}
	];

	let baby_state = [
		// { state: "dead", count: ### }
	]

	let home_count = 0,
		facility_count = 0,
		alive_count = 0,
		dead_count = 0,
		sick_count = 0,
		baby_warm_count = 0,
		baby_dead_count = 0,
		baby_sick_count = 0;

	data.maternal_birth_data.forEach((d) => {
		d.deliveryLocation === "home"
			? home_count++
			: d.deliveryLocation === "facility"
			? facility_count++
			: (facility_count += 0);

		d.motherState === "alive"
			? alive_count++
			: d.motherState === "dead"
			? dead_count++
			: d.motherState === "sick"
			? sick_count++
			: (sick_count += 0);

		d.babyState === "warm"
			? baby_warm_count++
			: d.babyState === "dead"
			? baby_dead_count++
			: d.babyState === "sick"
			? baby_sick_count++
			: (baby_sick_count += 0)
	});

	birth_location.push(
		{ location: "Home", births: home_count },
		{ location: "Facility", births: facility_count }
	);

	mother_state.push(
		{ state: "Alive", mothers: alive_count },
		{ state: "Dead", mothers: dead_count },
		{ state: "Sick", mothers: sick_count }
	);

	baby_state.push(
		{ state: "Warm", babies: baby_warm_count },
		{ state: "Dead", babies: baby_dead_count },
		{ state: "Sick", babies: baby_sick_count }
	)

	return { birth_location, mother_state, baby_state };
};
