import { BarChartComponent, AreaChartComponent } from "../../visuals";

import React from "react";

import { Panel, Row, Col, Container, Content, Header, InputPicker, Drawer, Button, Whisper, Badge } from "rsuite";
import { MenuPopover } from "../../panelHeader";

import { all_time_covid_cases, covid_cases_may, covid_cases_june, covid_cases_july, access_mode, covid_cases_april, covid_cases_march, months, covid_cases_february, covid_cases_january, charts } from "../../../data/data";

import { notification } from "../../notification";

import { PanelHeader } from "../../panelHeader";

import { _loadCovidSuspects } from "../../../data/covid/hbcStatus";

import firebase from "../../../config/firebaseConfig";

export const CovidBurden = () => {
	let [show, showInfo] = React.useState(false);
	let [autoInfo, showAutoInfo] = React.useState(false);
	let [month, setMonth] = React.useState("All time");
	let [chart, setChart] = React.useState("Bar Graph");
	let [accessMode, setAccessMode] = React.useState("National Overview");
	let [covidhbc, setCovidhbc] = React.useState([]);

	let exportChart = React.createRef();

	React.useEffect(() => {
		document.title = "Covid-19 — GAMERS"
		let hbc = []
		firebase
			.database()
			.ref("covid_screening_data")
			.on("value", (snapshot) => {
				let covidSuspects = [];
				snapshot.forEach((child) => {
					covidSuspects.push(child.val());
				});
				hbc = covidSuspects
				setCovidhbc(_loadCovidSuspects(hbc))
				// notification("info", "COVID-19 HBC Updates", "Recent Home Based Care Updates loaded")
			});
	}, []);

	const covidAreaChart = () => {
		return (
			<div style={{ height: 300, width: "100%" }}>
				{month === "All time" && <AreaChartComponent data={all_time_covid_cases} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "June" && <AreaChartComponent data={covid_cases_june} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "January" && <AreaChartComponent data={covid_cases_january} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "February" && <AreaChartComponent data={covid_cases_february} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "March" && <AreaChartComponent data={covid_cases_march} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "April" && <AreaChartComponent data={covid_cases_april} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "May" && <AreaChartComponent data={covid_cases_may} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "July" && <AreaChartComponent data={covid_cases_july} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{/* {notification("success", "COVID-19 Updates", "COVID-19 Cases from the last month loaded")} */}
			</div>
		);
	};

	const covidBarChart = () => {
		return (
			<div style={{ height: 300, width: "100%" }}>
				{month === "All time" && <BarChartComponent data={all_time_covid_cases} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "June" && <BarChartComponent data={covid_cases_june} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "January" && <BarChartComponent data={covid_cases_january} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "February" && <BarChartComponent data={covid_cases_february} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "March" && <BarChartComponent data={covid_cases_march} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "April" && <BarChartComponent data={covid_cases_april} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "May" && <BarChartComponent data={covid_cases_may} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{month === "July" && <BarChartComponent data={covid_cases_july} xkey="Date" ykey="New Cases" fill="#eb4334" />}
				{notification("success", "COVID-19 Updates", "COVID-19 Cases from the last month loaded")}
			</div>
		);
	};

	return (
		<Container style={{ padding: 10, paddingLeft: 65 }}>
			<Header style={{ margin: 10 }}>
				<span>GAMERS DATA HUB - {accessMode}</span>
				<h2>COVID-19</h2>
				<Button onClick={() => showInfo(true)} appearance="default" style={{ margin: 5 }}>
					Latest Updates
				</Button>
				<InputPicker data={access_mode} style={{ width: 224, margin: 5 }} placeholder="Access Level" value={accessMode} onChange={(value) => setAccessMode(value)} />
				<InputPicker data={months} style={{ width: 224, margin: 5 }} placeholder="Select Month" value={month} onChange={(value) => setMonth(value)} />
				<InputPicker data={charts} style={{ width: 224, margin: 5 }} placeholder="Select Chart Type" value={chart} onChange={(value) => setChart(value)} />
				<Button onClick={() => showAutoInfo(true)} appearance="default" style={{ margin: 5 }}>
					AI Generated Summary
				</Button>
			</Header>
			<Content>
				<Row>
					<Col md={18} sm={23} style={{ margin: 5 }}>
						<Panel header={<PanelHeader header={`${month} COVID-19 Cases`} type="covid" r={exportChart} />} bordered ref={exportChart}>
							<div style={{ height: 300, width: "100%" }}>{chart === "Area Graph" ? covidAreaChart() : covidBarChart()}</div>
						</Panel>
					</Col>
					<Col md={5} sm={23}>
						<Col sm={23} style={{ margin: 5 }}>
							<Panel bordered bodyFill style={{ display: "inline-block" }}>
								<Panel header="SUSPECTED CASES">
									<h2>90 656</h2>
								</Panel>
							</Panel>
						</Col>
						<Col sm={23} style={{ margin: 5 }}>
							<Panel bordered bodyFill style={{ display: "inline-block" }}>
								<Panel header="CONFIRMED DEATHS">
									<h2>2 392</h2>
								</Panel>
							</Panel>
						</Col>
						<Col sm={23} style={{ margin: 5 }}>
							<Panel bordered bodyFill style={{ display: "inline-block" }}>
								<Panel header="NEW CASES">
									<h2>682</h2>
								</Panel>
							</Panel>
						</Col>
					</Col>
					<Col md={10} sm={23} style={{ margin: 5 }}>
						<Panel header="Home Based Care (HBC) Statistics for COVID-19" bordered style={{ color: "#fff" }}>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={covidhbc} xkey="status" ykey="Number of Patients" fill="#6e65a0" />
							</div>
						</Panel>
					</Col>
					<Col md={7} sm={23} style={{ margin: 5 }}>
						<Panel header="COVID-19 Cases in the Month of May 2021" bordered style={{ color: "#fff" }}>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={covid_cases_may} xkey="Date" ykey="New Cases" fill="#eb4334" />
								{notification("info", "COVID-19 Updates", "COVID-19 Cases from May 2021 loaded")}
							</div>
						</Panel>
					</Col>
					<Col md={6} sm={23} style={{ margin: 5 }}>
						<Panel header="COVID-19 Cases in the Month of June 2021" bordered style={{ color: "#fff" }}>
							<div style={{ height: 200, width: "100%" }}>
								<BarChartComponent data={covid_cases_june} xkey="Date" ykey="New Cases" fill="#eb4334" />
								{notification("info", "COVID-19 Updates", "COVID-19 Cases from June 2021 loaded")}
							</div>
						</Panel>
					</Col>
				</Row>
			</Content>
			<Drawer placement={"bottom"} show={show} onHide={() => showInfo(false)}>
				<Drawer.Header>
					<Drawer.Title>Latest Updates</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					<p style={{ fontSize: 20 }}>Globally, as of 5:31pm CEST, 18 June 2021, there have been 177,108,695 confirmed cases of COVID-19, including 3,840,223 deaths, reported to WHO. As of 16 June 2021, a total of 2,378,482,776 vaccine doses have been administered.</p>
					<p style={{ fontSize: 20 }}>Africa has 3,750,515 confirmed cases</p>
					<br />
					<hr />
					<p style={{ fontSize: 14 }}>
						Source: <br />
						World Health Organization. <br />
						Data may be incomplete for the current day or week.
					</p>
				</Drawer.Body>
				<Drawer.Footer>
					<Button onClick={() => showInfo(false)} appearance="default">
						Got it
					</Button>
				</Drawer.Footer>
			</Drawer>
			<Drawer placement={"right"} show={autoInfo} onHide={() => showAutoInfo(false)}>
				<Drawer.Header>
					<Drawer.Title>AI Generated Summary</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					<p style={{ fontSize: 20 }}>Globally, as of 5:31pm CEST, 18 June 2021, there have been 177,108,695 confirmed cases of COVID-19, including 3,840,223 deaths, reported to WHO. As of 16 June 2021, a total of 2,378,482,776 vaccine doses have been administered.</p>
					<p style={{ fontSize: 20 }}>Africa has 3,750,515 confirmed cases</p>
					<br />
					<hr />
					<p style={{ fontSize: 14 }}>
						Source: <br />
						World Health Organization. <br />
						Data may be incomplete for the current day or week.
					</p>
				</Drawer.Body>
				<Drawer.Footer>
					<Button onClick={() => showAutoInfo(false)} appearance="primary">
						Got it
					</Button>
				</Drawer.Footer>
			</Drawer>
		</Container>
	);
};
