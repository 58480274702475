import { ChildDeliveries, DiseaseBurden } from "../../sections";
import { BarChartComponent, AreaChartComponent } from "../../visuals";

import React from "react";

import { Panel, Row, Col, Container, Content, Header, Loader } from 'rsuite'

import { population } from "../../../lib";

import { useFetchData } from "../../../hooks/useFetchHooks";

import { notification } from "../../notification";


export const ChildHealth = () => {

    let popn_data = useFetchData('population')
    let { year_popn, district_popn } = population(popn_data.data)

    const populationData = () => {
        switch (popn_data.status) {
            case 'loading':
                return <Loader content="Loading..." />;
            case 'success':
                notification('success', 'Population Data Loaded', 'Population per district ready to be visualised')
                return <AreaChartComponent data={district_popn} xkey="district" ykey="population" fill="#eb4334" />
            case 'error':
                notification('error')
                break;
        }
    }

    const populationPerYearData = () => {
        switch (popn_data.status) {
            case 'loading':
                return <Loader content="Loading..." />;
            case 'success':
                notification('success', 'Population Data Loaded', 'Data is ready to be visualised')
                return <BarChartComponent data={year_popn} xkey="year" ykey="population" fill="#8884d8" />
            case 'error':
                notification('error')
                break;
        }
    }

    return (
        <Container style={{ padding: 10, paddingLeft: 65}}>
            <Header style={{ margin: 10}}>
                <span>GAMERS DATA HUB</span>
                <h2>Child Health</h2>
            </Header>
            <Content>
                <Row>
                    <Col md={18} sm={23} style={{margin: 5}}>
                        <Panel header="Population Per Year in Karamoja Region" bordered>
                            <div style={{ height: 500, width: "100%"}}>
                                {populationData()}
                            </div>
                        </Panel>
                    </Col>
                    <Col md={5} sm={23} style={{margin: 5}}>
                        <Col sm={23} style={{ marginBottom: 5}}>
                            <Panel bordered bodyFill style={{ display: 'inline-block'}}>
                                {/* <img src="../../research.jpg" height="100%" width={240} /> */}
                                <Panel header="POPULATION IN NORTHERN UGANDA">
                                <p>
                                    <small>A general increase in the trend of population of Northern Uganda has been observed</small>
                                </p>
                                </Panel>
                            </Panel>
                        </Col>
                        <Col sm={23}>
                            <Panel shaded bordered bodyFill style={{ display: 'inline-block'}}>
                                <img src="../../research.jpg" height="100%" width={380} />
                                <Panel header="NAPAC">
                                <p>
                                    <small>Number of pregnant mothers in NAPAC is higher that all the other villages</small>
                                </p>
                                </Panel>
                            </Panel>
                        </Col>
                    </Col>
                    <Col md={7} sm={23} style={{margin: 5}}>
                        <Panel header="Population Per District In the Karamoja Region" bordered style={{ color: "#fff"}}>
                            <div style={{ height: 200, width: "100%"}}>
                                {populationPerYearData()}
                            </div>
                        </Panel>
                    </Col>
                    <Col md={8} sm={23} style={{ margin: 5}}>
                        <Panel header="Disease Burden in Karamoja Region" bordered>
                            <div style={{ height: 200, width: "100%"}}>
                                <DiseaseBurden />
                            </div>
                        </Panel>
                    </Col>
                    <Col md={8} sm={23} style={{ margin: 5}}>
                        <Panel header="Home Deliveries VS Facility Deliveries" bordered>
                            <div style={{ height: 200, width: "100%"}}>
                                <ChildDeliveries /> 
                            </div>
                        </Panel>
                    </Col>    
                </Row>
            </Content>
        </Container>
    )
}

